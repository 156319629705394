import "../../styles/organizations/organizations.scss";

import Modal from "bootstrap/js/dist/modal";

export function initProgramPage() {
  const parentSignedModalElement = document.getElementById("parentSignedModal");

  if (parentSignedModalElement) {
    new Modal(parentSignedModalElement).show();
  }
}
